<!-- main header -->
<title>Welcome to {{ companyName }}</title>
<section [ngClass]="{'mobile-version': isOnMobile, 'desktop-version': !isOnMobile}">
    <div class="menu">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <a class="navbar-brand" href="{{web}}">
                <img class="mainLogo" *ngIf="logoPath" [src]="logoPath" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ml-auto" style="margin: 0;">
                    <li class="nav-item" *ngIf="(isLoggedIn() || loggedIn) && (userPermissions && userPermissions.indexOf('DashboardAccess') !== -1)">
                        <a class="nav-link" [routerLink]="['/dashboard']">DASHBOARD</a>
                    </li>
                    <li class="nav-item" *ngIf="(isLoggedIn() || loggedIn) && (userPermissions && userPermissions.indexOf('EntityListAccess') !== -1)">
                        <a class="nav-link" [routerLink]="['/enterprise']">ENTITY LIST</a>
                    </li>
                    <li class="nav-item" *ngIf="(isLoggedIn() || loggedIn)">
                        <a class="nav-link" [routerLink]="['/notifications']">NOTIFICATIONS</a>
                    </li>
                    <li class="nav-item" *ngIf="(isLoggedIn() || loggedIn) && (userPermissions && userPermissions.indexOf('ScreeningAccess') !== -1)">
                        <a class="nav-link" [routerLink]="['/screening']">SCREENING</a>
                    </li>
                    <li class="nav-item" *ngIf="(isLoggedIn() || loggedIn) && (userPermissions && userPermissions.indexOf('ReportsAccess') !== -1)">
                        <a class="nav-link" [routerLink]="['/reporting']">REPORTING</a>
                    </li>
                    <li class="nav-item" *ngIf="displayResearchTab && (isLoggedIn() || loggedIn) && (userPermissions && userPermissions.indexOf('ResearchAccess') !== -1)">
                        <a class="nav-link" [routerLink]="['/research']">RESEARCH</a>
                    </li>
                    <li class="nav-item dropdown" *ngIf="(isLoggedIn() || loggedIn)">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-user-circle" style="font-size: 25px;margin-top: -3px;" aria-hidden="true"></i>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" [routerLink]="['/my-account']" *ngIf="userPermissions && userPermissions.indexOf('AccountSettingsAccess') !== -1">MY ACCOUNT</a>
                            <a class="dropdown-item" [routerLink]="['/settings']" *ngIf="(isLoggedIn() || loggedIn) && (userPermissions && userPermissions.indexOf('SettingsAccess') !== -1)">SETTINGS</a>
                            <a class="dropdown-item" (click)="showAbout()">ABOUT</a>
                            <a class="dropdown-item" [routerLink]="['/login']">LOGOUT</a>
                        </div>
                    </li>
                    <li class="nav-item" *ngIf="(!isLoggedIn() || !loggedIn) && !isOnboarding">
                        <a class="nav-link" [routerLink]="['/login']">LOGIN</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    <!-- main app container -->
    <div class="jumbotron" style="padding-top: 0px; padding-bottom: 0px; padding-left: 15px; padding-right: 15px; background-color: #f2f1f9;">
        <div class="row" style="margin: 0px;" *ngIf="(isLoggedIn() || loggedIn)">
            <div class="col-lg-4"></div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4 logged-in-as-admin" style="color:black;text-align: right;padding: 0px;font-size: 14px;">Logged in as <span style="font-size: 14px;color: #000;font-weight: 500;">{{userName}}</span></div>
        </div>
    </div>
    <div style="padding-top: 20px; padding-bottom: 20px; background-color: #f2f1f9;">
        <div class="">
            <div class="col-lg-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <!--Footer-->
    <footer *ngIf="!isOnMobile" class="page-footer font-small stylish-color-dark pt-4 mt-4">
        <!--Footer Links-->
        <div class="text-center text-md-left">
            <div class="row" style="margin: 0px;">
                <div class="col-md-6">
                    <div class="text-left">
                        <a href="{{web}}"><img *ngIf="logoPath" src="{{logoPath}}" alt="logo" height="auto"
                                width="120"></a><br>
                        <div style="height: 5px;"></div>
                        <p *ngIf="showFooterText" style="text-align: justify">{{footerText}}</p>
                    </div>
                </div>
                <div class="col-md-2">
                </div>
                <div class="col-md-4">
                    <div class="text-left">
                        <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">{{"CONTACT_US" | translate }}</h5>
                    </div>
                    <div class="text-left">
                        <ul class="list-unstyled">
                            <li>
                                <b>{{"SUPPORT" | translate}}: </b> <a href="mailto:{{supportEmail}}"
                                    [style.color]="mainColor">{{supportEmail}}</a>
                            </li>
                            <li>
                                <b>{{"WEB" | translate}}: </b> <a href="{{web}}" [style.color]="mainColor">{{web}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!--/.Footer Links-->
        <div style="height: 20px;background-color: white;"></div>
        <!--Copyright-->
        <div class="footer-copyright py-3 text-center">
            <p>All dates/times diplayed in UTC</p> Copyright © 2024 KYC3 sarl - ERMS - Enterprise Risk Management
            Server, v3.4.1 build 5d614be6
        </div>
        <!--/.Copyright-->
        <div style="height: 20px;background-color: white;"></div>
    </footer>
    <footer *ngIf="isOnMobile">
        <div class="footer-mobile">
            <a href="{{web}}">
                <img src="assets/images/logo.png" alt="KYC3" style="width: 80px; padding-right: 10px;" /> | <img
                    *ngIf="logoPath" src="{{logoPath}}" alt="logo" height="auto" width="120"></a>
        </div>
        <div class="footer-mobile">
            <div class="clearfix"></div>
            <p><small>©2024 KYC3 sarl. ERMS build f3833ab3</small></p>
        </div>
    </footer>
</section>
<!--/.Footer-->
